@keyframes fade-in-animation {
  0% {
    opacity: 0;
    transform: translateY(10px);
}
  100%{
  opacity: 1;
  transform: none;
  }


}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main{
  margin: 0;
  padding: 0;
  width: 100%;
}

section{
  padding: 4rem  0 4rem 0;
  width: 100%;
  align-content: center;
}


.flex{
  display: flex;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.no-margin{
  margin: 0;
}

.align-center{
  align-items: center;
}
.align-left{
  align-items: flex-start;
}

.container{
  width: 90%;
  max-width: 1024px;
}

.flex-group{
  gap: 1rem;
 
}


.button-style {
  appearance: button;
  backface-visibility: hidden;
  background-color: white;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: rgba(55,67,81,255);
  font-weight: 600;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.button-style:disabled {
  cursor: default;
}

.button-style:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.button-style:hover{
  background-color: rgba(55,67,81,255);
  color:white;
  border: 1px white solid;
}

.text-left{
  text-align: left;
}

.button-group{
  width: 100%;
}

.content{
  text-align: left;
  max-width: 480px;
}

.content p{
  margin: 0 0 20px;
  max-width: 600px;
}

.fade-in {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: fade-in-animation;
  backface-visibility: hidden;
  opacity: 0;
}

.center{
  margin:auto;
}


@media only screen and (max-width: 480px){

  .logo-container h1{
    font-size: 24px !important;
  }
  li{
    font-size: 18px;
  }
  #hero-content{
    flex-direction: column;
  }
  h1{
    font-size: 30px !important;
  }
  p{
    font-size: 18px !important;
    text-wrap: pretty;
  }
  #main-content{
    flex-direction: column;

  }
  .prev-img img{
    mask-image: none !important;
  }
  h3{
    font-size: 18px;
  }
  #features{
    gap: 1rem !important;
  }
  #feature-detail{
    font-size: 16px !important;
  }
  #feature-card{
    width: 85% !important;
  }
  .img-container{
    width: 100px !important;
    height: 100px !important;
  }
  #form-container{
    width: 70% !important;
  }

}