.container p{
    
    max-width: 500px;
    line-height: 1.7;

}

#feature-card img{
    width: 50%;
    margin: auto;
}

.img-container{
    
    
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    overflow: hidden;
}


#feature-card{
    width: 25%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: min(300px);
    margin-top: 0;
    padding: 1rem;
    background-color: #eefcff;
}

#features{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
   padding-left: 0;
    width: 100%;
   justify-content:center;
   gap: 2rem;
   background-color: white;
}

#card-content{

    height: 100%;
    width: 100%;
    justify-content: space-between;
}

#feature-detail{
    font-size: 14px;
}

.card {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .card-visible {
    opacity: 1;
    transform: translateY(0);
  }
  #card-content h3{
    font-weight: 400;
  }