#hero-section{
background-color: rgba(55,67,81,255);
color: white;
}
#intro{
    width: 100%;
}
#iphone-convo{
    
    top: 0;
    right: 30px;
    width: 90%;
    margin: 0 auto;
    background: white;
    max-width: 400px;
    margin: 0 auto;
    overflow: hidden;
    padding: 20px 20px 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-size: 17px;
    touch-action: pan-x pan-y;
    user-select: none;
    min-height: 520px;
}

#header{
    font-size: 60px;
    line-height: 1.1;
    margin: 0 0 30px;
    width: 100%;
    max-width: 480px;
}

.intro{
    font-size: 19px;
    line-height: 1.7;
    width: 100%;
    max-width: 480px;
    margin: 0 0 20px;
    text-align: left;
}

.text-message{
    max-width: 80%;
    word-wrap: break-word;
    margin: 0 0 12px;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;
    float: left;
    clear: both;
    display: inline-block;
    text-align: left;
}


.from-me{
    background-color: #5f92ee;
    float: right;
}
.text-message::before,
.text-message::after{
    content: "";
    position: absolute;
    bottom: -2px;
    height: 20px;
}

.from-me::before{
    right: -7px;
    border-right: 20px solid #5f92ee;
    border-bottom-left-radius: 16px 14px;
    transform: translate(0, -2px);
}

.from-me::after{
    right: -56px;
    width: 26px;
    background: white;
    border-bottom-left-radius: 10px;
    transform: translate(-30px, -2px);
}

.from-them{
    background: #EAEAEA;
    color: black;
}

.from-them::before{
    left: -7px;
    border-left: 20px solid #EAEAEA;
    border-bottom-right-radius: 16px 14px;
    transform: translate(0, -2px);

}

.from-them::after{
    left: 4px;
    width: 26px;
    background: white;
    border-bottom-right-radius: 10px;
    transform: translate(-30px, -2px);
}
#intro .button-group{
    max-width: 480px;
}

.message {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .message-visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .chat-container {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
  }
  
  /* .client {
    align-self: flex-end;
    background-color: #d1f7c4;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
  }
  
  .plumber {
    align-self: flex-start;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
  } */
  