.prev-img{
    position: relative;
    width: 100%;
    overflow: hidden;

}

.prev-img img{
    width: 100%;
    height: 100%;
    mask-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));
    border-radius: 35px;
    position: relative;
}


#main-section .container {
    justify-content: space-between;
    color: black;
}

#main-section{
    background-color:#f3f5f6;
    
}

#main-content div p{
    font-size: 17px;
}
#main-content{
    justify-content: space-between; 
   
}