.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    opacity: 1;
}

#form-container{
    width: min(50%,400px);
    background-color: white;
    padding: 1rem;
    border-radius: 15px;
}
#form-container form{
    height: 100%;
    gap: 1rem;
    width: 90%;
    margin: auto;
}
input{
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
}
