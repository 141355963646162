/* Container for the SVG */
.svg-container {
  width: 7.5%;
  max-width: 500px; /* Optional max width */
  height: auto; /* Adjust height automatically based on the width */
 /* Optional border to visualize the container */
  cursor: pointer;
  padding: 2px;
}

/* Ensure the SVG resizes to fit the container */
.svg-container svg {
  width: 90%;
  height: auto;
  display: block; /* Prevents inline space below the SVG */
}
