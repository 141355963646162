nav{
    width: 100%;
    height: 5rem;
    max-height: 5rem;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background-color: rgba(55,67,81,255);
   
}
nav ul{
    width: 90%;
    height: 100%;
    margin: auto;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    align-items: center;
    color: white;
}

.logo-container{

    height: 100%;
    position: relative;
    align-items: center;

}
.logo-container img{
    height: 100%;

    position: relative;
}

.logo-container h1{
    color: white;
    font-weight: 200;
}